import React from "react";

import IcoPlay from "../assets/img/ico-play.svg";

import t from "../locale";

const IndividualPlan = ({ title, text, lang }) => (
	<section className="individual-plan">
		<div className="container">
			<div className="row">
				<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
					<h2 className="color--main mb-40">{title}</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
					<p className="mb-50">{text}</p>
					<div className="individual-plan__cta">
						<img src={IcoPlay} alt="" />
						<a
							className="btn"
							href={
								lang === "pl"
									? "https://www.youtube.com/watch?v=mbpGiOvlc2k"
									: "https://www.youtube.com/watch?v=MXC7F6nGRRc"
							}
							target="_blank"
							rel="noreferrer"
							data-hover={t(
								"Dowiedz się więcej o naszym procesie produkcyjnym",
								lang
							)}
						>
							{t(
								"Dowiedz się więcej o naszym procesie produkcyjnym",
								lang
							)}
							<span className="btn-arrows"></span>
						</a>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-md-4 individual-plan__image">
					<img
						src={require("../assets/img/individual1.jpg")}
						alt="Paletten"
					/>
				</div>
				<div className="col-12 col-md-4 individual-plan__image">
					<img
						src={require("../assets/img/individual2.jpg")}
						alt="Paletten"
					/>
				</div>
				<div className="col-12 col-md-4 individual-plan__image">
					<img
						src={require("../assets/img/individual3.jpg")}
						alt="Paletten"
					/>
				</div>
			</div>
		</div>
	</section>
);

export default IndividualPlan;
